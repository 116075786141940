<template>
  <div>
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <!-- title text -->
      <b-card-text
        class="mb-2 text-center"
      >
        Please update your password.
      </b-card-text>
      <!--/ title text -->
      <validation-observer
        ref="changePasswordForm"
      >
        <!-- form -->
        <b-form
          class="mt-2"
          @submit.prevent="submitPasswordChangeForm"
        >
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`input_fields.new_password`)"
                  vid="password"
                  :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="account-new-password"
                      ref="password"
                      v-model="user.password"
                      :type="passwordFieldTypeNew"
                      name="password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`input_fields.confirm_password`)"
                  vid="password-confirm"
                  :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required, confirmed: 'password' }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="user.password_confirm"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ retype password -->

            <b-col
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('password_warning') }}</span>
                </div>
              </b-alert>
            </b-col>

            <b-col
              cols="12"
              class="mt-75"
            >
              <!-- server alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="serverError !== null"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span v-html="serverError" />
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BCardText, BAlert, BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
  confirmed,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BCardText,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      passwordValueOld: '',
      user: {},
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      hideOldPassword: true,
      required,
      regex,
      confirmed,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      noErrors: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['changeTemporaryPassword']),
    resetForm() {
      this.user.password = ''
      this.user.password_confirm = ''
      this.$refs.changePasswordForm.reset()
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    submitPasswordChangeForm() {
      return new Promise(resolve => {
        this.$refs.changePasswordForm.validate().then(async success => {
          if (success) {
            this.serverError = null
            this.loading = true
            try {
              const payload = {
                password: this.user.password,
              }
              await this.changeTemporaryPassword(payload)
              this.noErrors = true
              this.resetForm()
              this.$emit('success', { message: 'Password successfully updated' })
            } catch (error) {
              console.debug(`Onboarding - Password Save Error: ${error.message}`)
              if (error.response) {
                if (error.response.status < 500) {
                  this.serverError = error.response.data.message
                } else {
                  this.serverError = this.$t('errors.generic')
                }
              }
              this.$emit('error', { message: this.$t('errors.generic') })
            } finally {
              this.loading = false
            }
          }
        }).finally(() => resolve(this.noErrors))
      })
    },
  },
}
</script>
