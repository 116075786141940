<template>
  <div>
    <b-overlay
      :show="complete"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <form-wizard
        color="#1a63c9"
        shape="square"
        finish-button-text="Submit"
        class="mb-3"
        :start-index="startIndex"
        @on-complete="wizardComplete"
      >
        <div slot="title">
          <b-card-title
            class="mb-1 pt-1 font-weight-bold"
            title-tag="h2"
            v-html="$t(`welcome`)"
          />
          <b-card-text
            slot="subtitle"
            class="mb-2"
          >
            {{ $t(`onboarding.subtitle`) }}
          </b-card-text>
        </div>

        <template
          slot="step"
          scope="props"
        >
          <wizard-step
            :key="props.tab.title"
            :tab="props.tab"
            :transition="props.transition"
            :index="props.index"
          />
        </template>

        <!-- Terms & Conditions tab -->
        <tab-content
          :title="$t('onboarding.steps.terms')"
          :before-change="submitTermsForm"
        >
          <accept-terms-step
            ref="termsStep"
            @success="onSuccess"
            @error="onError"
          />
        </tab-content>

        <!-- Change Password Tab -->
        <tab-content
          :title="$t('onboarding.steps.password')"
          :before-change="submitPasswordChangeForm"
        >
          <change-password-step
            ref="passwordStep"
            @success="onSuccess"
            @error="onError"
          />
        </tab-content>

        <template
          slot="footer"
          scope="props"
        >
          <div class="wizard-footer-left" />
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              @click.native="props.nextTab()"
            >
              <span>{{ $t('onboarding.steps.next') }}</span>
            </wizard-button>

            <wizard-button
              v-else
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              @click.native="props.nextTab()"
            >
              <span>{{ props.isLastStep ? $t('onboarding.steps.finish') : $t('onboarding.steps.next') }}</span>

            </wizard-button>
          </div>
        </template>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import {
  FormWizard, TabContent, WizardStep, WizardButton,
} from 'vue-form-wizard'
import {
  BOverlay,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import AcceptTermsStep from '@/views/pages/onboarding/steps/AcceptTermsStep.vue'
import ChangePasswordStep from '@/views/pages/onboarding/steps/ChangePasswordStep.vue'

export default {
  components: {
    BOverlay,
    BCardTitle,
    BCardText,
    FormWizard,
    TabContent,
    WizardButton,
    WizardStep,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AcceptTermsStep,
    ChangePasswordStep,
  },
  data() {
    return {
      complete: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    startIndex() {
      let index = 0
      if (this.activeUser) {
        const { terms_accepted_at, has_temporary_password } = this.activeUser
        // eslint-disable-next-line camelcase
        if (has_temporary_password && terms_accepted_at) {
          index = 1
        }
      }
      return index
    },
  },
  methods: {
    wizardComplete() {
      this.complete = true
      setTimeout(() => {
        this.$router.push({ path: '/', replace: true }).catch(() => {
          this.$router.go()
        })
      }, 1000)
    },
    async submitTermsForm() {
      const res = await this.$refs.termsStep.submitTermsForm()
      return res
    },
    async submitPasswordChangeForm() {
      const res = await this.$refs.passwordStep.submitPasswordChangeForm()
      return res
    },
    onSuccess(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success!',
          icon: 'CheckIcon',
          variant: 'success',
          text: event.message,
        },
      })
    },
    onError(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: event.message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
