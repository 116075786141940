<template>
  <validation-observer
    ref="verifyAccountForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="submitForm"
      >
        <!-- Verification Code -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Verification Code"
            vid="code"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="code"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="code"
              placeholder="Verification Code"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- server alert -->
        <b-alert
          v-height-fade.appear
          class="mt-1"
          variant="danger"
          :show="serverError !== null"
        >
          <div
            class="alert-body"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            <span v-html="serverError" />
          </div>
        </b-alert>

        <!-- submit buttons -->
        <b-button
          v-show="!hideSubmit"
          class="mt-1"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
        >
          <span>{{ $t('buttons.submit') }}</span>
        </b-button>

        <!-- Regenerate link -->

        <b-form-group>
          Didn't receive your code?
          <b-link
            @click="resendCode"
          >
            Resend Verification Code
          </b-link>
        </b-form-group>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  BFormGroup,
  BFormInput,
  BForm,
  BLink,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
} from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BAlert,
    BFormGroup,
    BFormInput,
    BForm,
    BLink,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    hideSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: null,
      required,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      noErrors: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
    ...mapActions('auth', ['verifyAccount', 'resendVerificationCode']),
    submitForm() {
      return new Promise(resolve => {
        this.$refs.verifyAccountForm.validate().then(async success => {
          if (success) {
            this.serverError = null
            this.loading = true
            try {
              await this.verifyAccount({ otp: this.code })
              this.noErrors = true
              this.$emit('verifySuccess', { message: 'Your DenTyme account was successfully verified' })
            } catch (e) {
              console.debug(`Error in submitForm in VerifyAccountForm: ${e.message}`)
              this.$emit('verifyError', { message: 'Could not verify your DenTyme account' })
            } finally {
              this.loading = false
            }
          }
        }).finally(() => resolve(this.noErrors))
      })
    },
    async resendCode() {
      try {
        await this.resendVerificationCode()
        this.$emit('resendCodeSuccess', { message: 'Verification code resent' })
      } catch (e) {
        console.debug(`Error in resendCode() in VerifyAccountForm: ${e.message}`)
        this.$emit('resendCodeError', { message: 'Could not resend verification code' })
      }
    },
  },
}
</script>
