<template>
  <div>
    <b-overlay
      :show="complete"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <form-wizard
        ref="billingWizard"
        color="#1a63c9"
        shape="square"
        finish-button-text="Submit"
        class="mb-3"
        :start-index="startIndex"
        @on-complete="wizardComplete"
      >
        <div slot="title">
          <b-card-title
            class="mb-1 pt-1 font-weight-bold"
            title-tag="h2"
            v-html="$t(`welcome`)"
          />
          <b-card-text
            slot="subtitle"
            class="mb-2"
          >
            {{ $t(`onboarding.subtitle`) }}
          </b-card-text>
        </div>

        <template
          slot="step"
          scope="props"
        >
          <wizard-step
            :key="props.tab.title"
            :tab="props.tab"
            :transition="props.transition"
            :index="props.index"
          />
        </template>

        <!-- Create Account tab -->
        <!-- <tab-content
          :title="$t('onboarding.steps.create_account')"
        /> -->

        <!-- Verify Account Tab -->
        <tab-content
          :title="$t('onboarding.steps.verify')"
          :before-change="verifyAccount"
        >
          <account-verify-step ref="verifyAccountStep" />
        </tab-content>

        <!-- Office Details -->
        <tab-content
          :title="$t('onboarding.steps.office_details')"
          :before-change="submitOfficeForm"
        >
          <office-setup-step ref="officeForm" />
        </tab-content>

        <!-- Billing -->
        <tab-content
          :title="$t('onboarding.steps.billing')"
          :before-change="submitBillingForm"
        >
          <billing-step
            v-if="billingCustomerId"
            ref="billingForm"
            :billing-customer-id="billingCustomerId"
            @success="onBillingSuccess"
            @error="onBillingError"
            @submitting="onBillingSubmitting"
          />
        </tab-content>

        <!-- Add Users -->
        <tab-content
          :title="$t('onboarding.steps.add_users')"
          :before-change="setOnboardingStatus"
        >
          <add-users-step v-if="billingCustomerId" />
        </tab-content>

        <template
          slot="footer"
          scope="props"
        >
          <div class="wizard-footer-left" />
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              :disabled="billingSubmitting"
              @click.native="props.nextTab()"
            >
              <b-spinner
                v-if="billingSubmitting"
                small
              />
              <span :class="{ 'pl-1': billingSubmitting }">{{ $t('onboarding.steps.next') }}</span>
            </wizard-button>

            <wizard-button
              v-else
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              @click.native="props.nextTab()"
            >
              <span>{{ props.isLastStep ? $t('onboarding.steps.finish') : $t('onboarding.steps.next') }}</span>

            </wizard-button>
          </div>
        </template>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import {
  FormWizard, TabContent, WizardStep, WizardButton,
} from 'vue-form-wizard'
import {
  BOverlay,
  BCardTitle,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BillingStep from '@/views/pages/onboarding/steps/BillingStep.vue'
import AddUsersStep from '@/views/pages/onboarding/steps/AddUsersStep.vue'
import AccountVerifyStep from '@/views/pages/onboarding/steps/AccountVerifyStep.vue'
import OfficeSetupStep from '@/views/pages/onboarding/steps/OfficeSetupStep.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BOverlay,
    BCardTitle,
    BCardText,
    BSpinner,
    FormWizard,
    TabContent,
    WizardButton,
    WizardStep,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BillingStep,
    AddUsersStep,
    AccountVerifyStep,
    OfficeSetupStep,
  },
  data() {
    return {
      billingSubmitting: false,
      complete: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    startIndex() {
      let index = 0
      if (this.activeUser) {
        const { state, office } = this.activeUser
        if (state === 'unverified') {
          index = 0
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!office) {
            index = 1
          } else {
            // eslint-disable-next-line no-lonely-if
            if (state === 'activated' && !office.onboarding_completed_at) {
              index = 3
            } else {
              index = 2
            }
          }
        }
      }
      return index
    },
    billingCustomerId() {
      if (this.activeUser) {
        if (this.activeUser.office && this.activeUser.office.billing_customer) {
          return this.activeUser.office.billing_customer.id
        }
      }
      return null
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions('office', ['completeOnboarding']),
    wizardComplete() {
      this.complete = true
      setTimeout(() => {
        this.$router.push({ path: '/', replace: true }).catch(() => {
          this.$router.go()
        })
      }, 1000)
    },
    onBillingSuccess() {
      this.billingSubmitting = false
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success!',
          icon: 'CheckIcon',
          variant: 'success',
          text: 'You have successfully subscribed to DenTyme.',
        },
      })
    },
    onBillingError() {
      this.billingSubmitting = false
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error!',
          icon: 'XIcon',
          variant: 'danger',
          text: 'There was an error subscribing to DenTyme.',
        },
      })
    },
    onBillingSubmitting() {
      this.billingSubmitting = true
    },
    async setOnboardingStatus() {
      const payload = {
        id: this.activeUser.office.id,
        onboarding_completed_at: new Date().toISOString(),
      }
      await this.completeOnboarding(payload)
      return true
    },
    async submitBillingForm() {
      // call child component
      const res = await this.$refs.billingForm.submitForm()
      return res
    },
    async verifyAccount() {
      const res = await this.$refs.verifyAccountStep.verifyAccount()
      return res
    },
    async submitOfficeForm() {
      const res = await this.$refs.officeForm.createOffice()
      return res
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
