<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25">{{ $t('onboarding.optional_users') }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.add-user-modal
      variant="primary"
      class="mt-5"
    >
      <feather-icon
        icon="UserPlusIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('onboarding.add_user') }}</span>
    </b-button>
    <b-row>
      <b-col cols="12">
        <b-table
          :fields="fields"
          :items="users"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          class="mt-2"
        >
          <template #cell(office)>
            {{ officeName }}
          </template>
          <template #cell(roles)="data">
            <b-badge
              v-for="item in data.item.roles"
              :key="item.id"
              class="badge"
              variant="primary"
            >
              {{ item.description }}
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            {{ data.value | moment('LLL') }}
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="(data.item.state === 'deactivated' || data.item.state === 'unverified') && data.item.deactivated_at"
              class="badge"
              variant="danger"
            >
              Deactivated
            </b-badge>
            <b-badge
              v-else
              class="badge"
              variant="success"
            >
              Active
            </b-badge>
          </template>
          <!-- <template #cell(approved)="data">
            <b-badge
              pill
              variant="success"
            >
              {{ data.value === true ? 'Yes' : 'No' }}
            </b-badge>
          </template>
          <template #cell(number_of_employees)="data">
            {{ data.item.responses.number_of_employees }}
          </template>
          <template #cell(lease_info)="data">
            {{ data.item.responses.lease_info }}
          </template>
          <template #cell(created_at)="data">
            {{ data.value | moment('LLL') }}
          </template>
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="approveRequest(data.item.id)"
            >
              Re-approve for Registration
            </b-button>
          </template> -->
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
        <p
          v-if="users.length == 0"
          class="text-center"
        >
          {{ $t('no_data') }}
        </p>
        <b-col
          cols="12"
        >
          <b-pagination
            v-if="users.length > 0"
            v-model="currentPage"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-col>
    </b-row>
    <add-user-modal
      @success="handleSuccess"
      @error="handleError"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import {
  BAlert, BButton, BTable, BCol, BRow, BPagination, VBModal, BBadge,
} from 'bootstrap-vue'
import AddUserModal from '@/views/components/modal/AddUserModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BButton,
    BTable,
    BCol,
    BRow,
    // BBadge,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    AddUserModal,
    BBadge,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      fields: [
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'office', label: 'Office' },
        { key: 'roles', label: 'Roles' },
        { key: 'created_at', label: 'Created Date' },
        { key: 'status', label: 'Status' },
        // { key: 'actions', label: 'Actions' },
      ],
      perPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('role', ['roles']),
    users() {
      let list = []
      if (this.activeUser && this.activeUser.office && this.activeUser.office.users) {
        list = this.activeUser.office.users.filter(u => u.first_name !== 'Patient')
      }
      return list
    },
    officeName() {
      let name = null
      if (this.activeUser && this.activeUser.office) {
        name = this.activeUser.office.name
      }
      return name
    },
  },
  async created() {
    await this.getRoles()
  },
  methods: {
    ...mapActions('role', ['getRoles']),
    findRole(id) {
      return this.roles.find(r => r.id === id)
    },
    handleError(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: event.message,
        },
      })
    },
    handleSuccess(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: event.message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
  .badge {
    margin: 0.2em;
  }
</style>
