<template>
  <div>
    <!-- title text -->
    <b-card-text
      class="mb-2 text-center"
    >
      Please read the Terms & Conditions below.
    </b-card-text>
    <!--/ title text -->
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <div
        v-if="termsAndConditions"
        class="overflow-auto"
      >
        <markdown-it-vue
          class="md-body"
          :content="termsAndConditions"
        />

        <validation-observer
          ref="termsForm"
          #default="{invalid}"
        >
          <b-form
            id="terms-form"
            class="mt-3"
            @submit.prevent="submitTermsForm"
          >
            <validation-provider
              #default="{ errors }"
              name="Terms"
              vid="terms-checkbox"
              rules="required"
            >
              <b-form-checkbox
                id="terms-checkbox"
                v-model="termsSelected"
                name="terms-checkbox"
                :unchecked-value="null"
              >
                <span>I have read, agree and consent to DenTyme's
                  Terms and Conditions</span>
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- submit buttons -->
            <b-button
              v-show="!hideSubmit"
              class="mt-1"
              type="submit"
              variant="primary"
              block
              :disabled="invalid || loading"
            >
              <span>{{ $t('buttons.submit') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
        <hr>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import {
  BOverlay, BCardText, BForm, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
} from '@validations'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BOverlay,
    BCardText,
    BForm,
    BFormCheckbox,
    MarkdownItVue,
    ValidationObserver,
    ValidationProvider,
    BButton,
  },
  props: {
    hideSubmit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      termsSelected: null,
      noErrors: false,
      loading: false,
      serverError: null,
    }
  },
  computed: {
    ...mapGetters('app', ['termsAndConditions']),
  },
  async created() {
    await this.getTermsAndConditions()
  },
  methods: {
    ...mapActions('app', ['getTermsAndConditions']),
    ...mapActions('user', ['acceptTerms']),
    async submitTermsForm() {
      return new Promise(resolve => {
        this.$refs.termsForm.validate().then(async success => {
          if (success) {
            this.serverError = null
            this.loading = true
            try {
              await this.acceptTerms()
              this.noErrors = true
              this.$emit('success', { message: 'Successfully accepted Terms & Conditions' })
            } catch (e) {
              console.debug(`Error in submitForm in AcccptTermsStep: ${e.message}`)
              this.$emit('error', { message: 'Error accepting Terms & Conditions' })
            } finally {
              this.loading = false
            }
          }
        }).finally(() => resolve(this.noErrors))
      })
    },
  },
}
</script>
