<template>
  <div>
    <stripe-billing-table
      :customer-email="billingCustomer.email"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex'
import { has } from 'lodash'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    StripeBillingTable: () => import('@/views/components/stripe/StripeBillingTable.vue'),
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    billingCustomerId: String,
    // eslint-disable-next-line vue/require-default-prop
    // eslint-disable-next-line vue/require-prop-type-constructor
  },
  data() {
    return {
      every3months: true,
      stripe: null,
      cardElement: null,
      complete: false,
      focused: false,
      submitting: false,
      selectedProduct: null,
      stripeToken: process.env.VUE_APP_STRIPE_API_KEY,
      stripeOptions: {
        iconStyle: 'solid',
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            // fontSize: "16px",
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#EA5455',
            iconColor: '#EA5455',
          },
        },
      },
      customerPayload: {
        name: null,
      },
      card: null,
      loadingError: false,
      noErrors: false,
      required,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('billing', ['products', 'billingCustomer', 'subscriptionLineItems']),
    activeSubscription() {
      return !!(this.billingCustomer && this.billingCustomer.active_subscription)
    },
    freeProduct() {
      if (has(this.selectedProduct, 'price.amount')) {
        if (this.selectedProduct.price.amount === '0.00') {
          return true
        }
      }
      return false
    },
    sortedProducts() {
      // eslint-disable-next-line prefer-const
      let sorted = []
      if (this.products) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (this.every3months) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.products.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.products[i].price.length; j++) {
              if (this.products[i].price[j].recurring_interval_count === 3) {
                const obj = { ...this.products[i] }
                obj.price = this.products[i].price[j]
                sorted.push(obj)
              }
            }
          }
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.products.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.products[i].price.length; j++) {
              if (this.products[i].price[j].recurring_interval_count === 1) {
                const obj = { ...this.products[i] }
                obj.price = this.products[i].price[j]
                sorted.push(obj)
              }
            }
          }
        }
        sorted = sorted.sort((a, b) => a.price.amount - b.price.amount)
      }
      return sorted
    },
  },
  watch: {
    selectedProduct(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.prepareCardElement()
      }
    },
  },
  async created() {
    try {
      await this.getBillingCustomer(this.billingCustomerId)
      await this.getProducts()
      // if (this.activeSubscription) {
      //   this.$emit('success')
      // }
    } catch (e) {
      this.loadingError = true
      this.$router.push({
        name: 'misc-error',
      })
    }
  },
  methods: {
    ...mapActions('auth', ['recalculateState']),
    ...mapActions('billing', ['getBillingCustomer', 'getProducts', 'createSubscription', 'updateSubscription', 'calculateSubscriptionPrice', 'createCheckoutSession']),
    async selectProduct(product) {
      this.selectedProduct = product
      try {
        await this.calculateSubscriptionPrice({ billing_customer_id: this.billingCustomerId, price_id: this.selectedProduct.price.id })
      } catch (e) {
        this.loadingError = true
        throw e
      }
    },
    async checkoutProduct(product) {
      try {
        const res = await this.createCheckoutSession({ stripe_price_id: product.price.stripe_price_id, billing_customer_id: this.billingCustomerId })
        window.location.replace(res.data.url)
      } catch (e) {
        this.$emit('error')
        this.loadingError = true
        throw e
      }
    },
    prepareCardElement() {
      // this function initializes the card input element using Stripe.js
      // the third party Vue component for this didn't work properly so using vanilla.js instead
      const card = document.getElementById('card-element')
      if (card) {
        const { Stripe } = window
        this.stripe = Stripe(this.stripeToken)
        const elements = this.stripe.elements()
        this.cardElement = elements.create('card', this.stripeOptions)
        this.cardElement.mount('#card-element')
        this.cardElement.on('change', this.onChange)
        this.cardElement.on('focus', () => {
          this.focused = true
        })
        this.cardElement.on('blur', () => {
          this.focused = false
        })
      }
    },
    onChange(event) {
      this.$refs.paymentForm.setErrors({
        card2: [],
      })
      this.complete = false
      if (event.error) {
        this.$refs.paymentForm.setErrors({
          card2: [event.error.message],
        })
      }
      if (event.complete) {
        this.complete = true
        this.$refs.paymentForm.setErrors({
          card2: [],
        })
      }
      if (event.empty) {
        this.card = null
      } else if (!this.card) {
        this.card = event.brand
      }
    },
    async createPaymentMethod({
      card,
      isPaymentRetry,
      invoiceId,
    }) {
      try {
        // create a payment method
        const response = await this.stripe.createPaymentMethod({
          type: 'card',
          card,
          billing_details: {
            name: this.customerPayload.name,
          },
        })
        if (response.error) {
          // this.onChange(response);
          throw response
        } else if (isPaymentRetry) {
          // Update the payment method and retry invoice payment
          await this.retryInvoiceWithNewPaymentMethod({
            customerId: this.billingCustomer.stripe_customer_id,
            paymentMethodId: response.paymentMethod.id,
            invoiceId,
            priceId: this.selectedProduct.price.stripe_price_id,
          })
        } else if (this.complete) {
          // Create the subscription
          await this.createStripeSubscription({
            customerId: this.billingCustomer.stripe_customer_id,
            paymentMethodId: response.paymentMethod.id,
            priceId: this.selectedProduct.price.stripe_price_id,
          })
        }
      } catch (e) {
        console.error(`Error in createPaymentMethod: ${e.message}`)
        throw e
      }
    },
    async createStripeSubscription({
      // eslint-disable-next-line no-unused-vars
      customerId,
      paymentMethodId,
      priceId,
    }) {
      try {
        const payload = {
          billing_customer_id: this.billingCustomer.id,
          stripe_payment_method_id: paymentMethodId,
          price_id: this.selectedProduct.price.id,
        }
        // make call to backend
        const response = await this.createSubscription(payload)
        localStorage.setItem('latestSubscriptionId', response.data.id)
        const subscription = response.data.stripe_subscription_object
        if (subscription.error) {
          // If the card is declined, display an error to the user.
          // this.onChange(subscription);
          // The card had an error when trying to attach it to a customer
          throw subscription
        } else {
          // Some payment methods require a customer to do additional
          // authentication with their financial institution.
          // Eg: 2FA for cards.
          await this.handlePaymentThatRequiresCustomerAction({
            subscription,
            paymentMethodId,
            priceId,
          })
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail. You will
          // get a requires_payment_method error.
          await this.handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId })

          // No more actions required. Provision your service for the user.
        }
      } catch (e) {
        console.error(`Error in createStripeSubscription: ${e.message}`)
        // this.onChange(e);
        throw e
      }
    },
    // eslint-disable-next-line consistent-return
    async handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
    }) {
      try {
        if (subscription && subscription.status === 'active') {
          // subscription is active, no customer actions required.
          return {
            subscription,
            priceId,
            paymentMethodId,
          }
        }

        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent
        if (
          paymentIntent.status === 'requires_action'
            || (isRetry === true && paymentIntent.status === 'requires_payment_method')
        ) {
          const cardPayment = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          if (cardPayment.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            // this.onChange(cardPayment);
            throw cardPayment
          } else if (cardPayment.paymentIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            return {
              priceId,
              subscription,
              invoice,
              paymentMethodId,
            }
          }
        } else {
          // No customer action needed
          return {
            subscription,
            priceId,
            paymentMethodId,
          }
        }
      } catch (e) {
        console.error(`Error in handlePaymentThatRequiresCustomerAction: ${e.message}`)
        // this.onChange(e);
        throw e
      }
    },

    async retryInvoiceWithNewPaymentMethod({
      // eslint-disable-next-line no-unused-vars
      customerId,
      paymentMethodId,
      invoiceId,
      priceId,
    }) {
      try {
        const subscriptionId = localStorage.getItem('latestSubscriptionId')
        const payload = {
          id: subscriptionId,
          stripe_payment_method_id: paymentMethodId,
          stripe_invoice_id: invoiceId,
          retry: true,
        }
        const response = await this.updateSubscription(payload)
        const retriedInvoice = response.data.stripe_invoice
        // If the card is declined, display an error to the user.
        if (retriedInvoice.error) {
          // this.onChange(retriedInvoice);
          throw retriedInvoice
        } else {
          const isRetry = true
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          await this.handlePaymentThatRequiresCustomerAction({
            invoice: retriedInvoice,
            priceId,
            paymentMethodId,
            isRetry,
          })
          // No more actions required. Provision your service for the user.
          // clear localStorage here
        }
      } catch (e) {
        console.error(`Error in retryInvoiceWithNewPaymentMethod: ${e.message}`)
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        // this.onChange(e);
        throw e
      }
    },

    async handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
    }) {
      try {
        if (subscription && subscription.status === 'active') {
          // subscription is active, no customer actions required.
          return {
            subscription,
            priceId,
            paymentMethodId,
          }
        } if (
          subscription.latest_invoice.payment_intent.status
            === 'requires_payment_method'
        ) {
          // Using localStorage to store the state of the retry here
          // (feel free to replace with what you prefer)
          // Store the latest invoice ID and status
          localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
          localStorage.setItem(
            'latestInvoicePaymentIntentStatus',
            subscription.latest_invoice.payment_intent.status,
          )

          throw new Error({
            message: subscription.latest_invoice.payment_intent.last_payment_error.message,
          })
        } else {
          return {
            subscription,
            priceId,
            paymentMethodId,
          }
        }
      } catch (e) {
        console.error(`Error in handleRequiresPaymentMethod: ${e.message}`)
        throw e
      }
    },
    async subscribeFree() {
      if (this.freeProduct) {
        try {
          this.submitting = true
          this.$emit('submitting')
          const payload = {
            billing_customer_id: this.billingCustomer.id,
            stripe_payment_method_id: null,
            price_id: this.selectedProduct.price.id,
          }
          // make call to backend
          await this.createSubscription(payload)
          // this.$store.commit('SET_ONBOARDING_SUCCESS', true)
          setTimeout(() => {
            this.submitting = false
            // Clear cache
            localStorage.removeItem('latestSubscriptionId')
            localStorage.removeItem('latestInvoiceId')
            localStorage.removeItem('latestInvoicePaymentIntentStatus')
            this.$emit('success')
          }, 1000)
        } catch (e) {
          setTimeout(() => {
            this.submitting = false
            this.$emit('error')
          }, 1000)
        }
      } else {
        this.submitting = false
        this.$emit('error')
      }
    },
    submitForm() {
      return new Promise(resolve => {
        this.$refs.paymentForm.validate().then(async result => {
          if (result) {
            try {
              this.submitting = true
              this.$emit('submitting')
              // If a previous payment was attempted, get the lastest invoice
              const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus')
              if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
                const invoiceId = localStorage.getItem('latestInvoiceId')
                const isPaymentRetry = true
                // create new payment method & retry payment on invoice with new payment method
                await this.createPaymentMethod({
                  card: this.cardElement,
                  isPaymentRetry,
                  invoiceId,
                })
              } else {
              // create new payment method & create subscription
                await this.createPaymentMethod({
                  card: this.cardElement,
                })
              }
              // Clear cache
              localStorage.removeItem('latestSubscriptionId')
              localStorage.removeItem('latestInvoiceId')
              localStorage.removeItem('latestInvoicePaymentIntentStatus')
              this.$emit('success')
              this.noErrors = true
              // setTimeout(() => {
              //   vm.submitting = false
              //   // Clear cache
              //   localStorage.removeItem('latestSubscriptionId')
              //   localStorage.removeItem('latestInvoiceId')
              //   localStorage.removeItem('latestInvoicePaymentIntentStatus')
              //   vm.$emit('success')
              // }, 1000)
            } catch (e) {
              const vm = this
              setTimeout(() => {
                if (e.response && e.response.data) {
                  vm.onChange(e.response.data)
                } else {
                  console.debug(`Error in BillingForm submitForm(): ${e.message}`)
                  vm.onChange(e)
                }
                vm.$emit('error')
                console.debug(`Subscription error: ${e.message}`)
              }, 1000)
            } finally {
              this.submitting = false
            }
          }
        }).finally(() => resolve(this.noErrors))
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
.line-items {
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  list-style: none;
}

.line-items li {
  height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.vue-form-wizard .wizard-nav-pills {
  justify-content: center;
}
.product-list {
  display:flex; flex-direction: row; justify-content: space-evenly; list-style-type: none;
}
.product-list li {
  width: 90%;
}
</style>
