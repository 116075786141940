<template>
  <div>
    <section
      id="office-setup"
      class="text-center"
    >
      <!-- title text -->
      <b-card-text
        class="mb-2 text-center"
      >
        {{ $t(`onboarding.office_setup`) }}
      </b-card-text>
      <!--/ title text -->
    </section>

    <!-- Office Setup Form -->
    <add-office-form
      ref="officeSetupForm"
      :hide-submit="true"
      @success="onSuccess"
      @error="onError"
    />
  </div>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import AddOfficeForm from '@/views/components/forms/AddOfficeForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    AddOfficeForm,
  },
  data() {
    return {}
  },
  methods: {
    async createOffice() {
      const res = await this.$refs.officeSetupForm.submitForm()
      return res
    },
    onSuccess(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: event.message,
        },
      })
    },
    onError(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: event.message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
</style>
