<template>
  <div>
    <section
      id="account-verify"
      class="text-center"
    >
      <b-img
        :src="require('@/assets/images/pages/verify_account.svg')"
        class="account-verify-img"
        fluid
        alt="Responsive image"
      />

      <b-card-title
        class="mt-1 mb-1"
        title-tag="h4"
      >
        A verification code has been sent to the e-mail address that you registered. Please enter the verification code from that e-mail.
      </b-card-title>

      <b-card-text
        class="mt-1 mb-1"
      >
        The verification code will expire 24 hours after being e-mailed to you.
      </b-card-text>
    </section>

    <!-- Verify Form -->
    <verify-account-form
      ref="verifyAccountForm"
      :hide-submit="true"
      @resendCodeSuccess="onSuccess"
      @resendCodeError="onError"
      @verifySuccess="onSuccess"
      @verifyError="onError"
    />
  </div>
</template>

<script>
import { BCardTitle, BCardText, BImg } from 'bootstrap-vue'
import VerifyAccountForm from '@/views/components/forms/VerifyAccountForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardTitle,
    BCardText,
    BImg,
    VerifyAccountForm,
  },
  data() {
    return {}
  },
  methods: {
    async verifyAccount() {
      const res = await this.$refs.verifyAccountForm.submitForm()
      return res
    },
    onSuccess(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: event.message,
        },
      })
    },
    onError(event) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: event.message,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.account-verify-img {
  max-height: 150px;
}
</style>
