<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-row>
      <!-- Left Text-->
      <b-col
        cols="12"
      >
        <!-- wizard -->
        <billing-wizard v-if="billingUser" />
        <user-wizard v-else />
        <!-- /wizard -->
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import DentymeLogo from '@/views/components/dentyme-logo/DentymeLogo.vue'
import {
  BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import BillingWizard from './wizards/BillingWizard.vue'
import UserWizard from './wizards/UserWizard.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import DentymeInfoSlider from '@/views/components/dentyme-info-slider/DentymeInfoSlider.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BillingWizard,
    UserWizard,
  },
  data() {
    return {
      // Store the value in data before we update the value so later we can restore the same value when user leaves the page
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    billingUser() {
      if (this.activeUser) {
        if (this.activeUser.currentRole) {
          // only office manager or dentists get the billing flow
          if (this.activeUser.currentRole.name === 'office_manager' || this.activeUser.currentRole.name === 'dentist') {
            return true
          }
        }
      }
      return false
    },
  },
  created() {
    // Set `appConfig` when page/SFC is created
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    // Restore the state value of `appConfig` when page/SFC is destroyed
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  methods: {
  },
}
</script>

<style lang="scss">
  @import '@/assets/scss/variables/_variables.scss';
  .brand-text {
    color: $primary;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
  }
</style>
